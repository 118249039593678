import React, { useState, useMemo } from "react";
import SelectableView, { MenuButton } from "../../components/SelectableView";
import MemberMenuButton from "../../components/SelectableView/MemberMenuButton";
import AddIcon from "@mui/icons-material/Add";
import BaseMenuButton from "../../components/SelectableView/BaseMenuButton";
import usePageApi from "../../hooks/usePageApi";
import useLookup from "../../hooks/useLookup";
import CitizenshipContent from "./CitizenshipContent";
import CitizenshipForm from "./CitizenshipForm";
import { getAge } from "../../utils/helpers";
import PageContainer from "../../components/PageContainer";
import ScreenProvider from "../../containers/ScreenProvider";
import usePageNav from "../../hooks/usePageNav";
import { some } from "lodash";
import useDynamicNav from "../../hooks/useDynamicNav";
import NoRecord from "../../assets/icons/citizenship.svg";

const Citizenship = ({ screenId }) => {
  const { navigations } = usePageNav();
  const { handleNext } = useDynamicNav({ screenId });
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const { getOptions, lkpFlatten, getLkpMatch, lkpRaw } =
    useLookup("Citizenship");

  const toggleAddModal = () => setOpenAddModal(!openAddModal);
  const toggleEditModal = () => setOpenEditModal(!openEditModal);

  const {
    pageData: Data,
    saveJson,
    retrieveJson,
    labels,
  } = usePageApi({
    screenId: 150,
    screenDefn: "/dashboard/consumer-app/intake-app/hoh/citizenship-immig",
    callRetrieveStatus: true,
  });

  const { pageData, isFetchingData } = usePageApi({
    screenId: 150,
    screenDefn: "dashboard/consumer-app/intake-app/summary/homePage",
  });

  const onNext = () => {
    handleNext();
    // navigations.toAuthRep();
  };

  const onBack = () => {
    if (pageData.length === 1) {
      navigations.toMemberSummary();
    } else {
      navigations.toRelationShip();
    }
  };

  return (
    <PageContainer
      title="Citizenship Information"
      onNext={onNext}
      onBack={onBack}
    >
      <SelectableView
        data={pageData}
        loading={isFetchingData}
        title="Members"
        showDelete={false}
        showAdd={false}
        image={
          <img
            src={NoRecord}
            style={{
              height: "160px",
              width: "250px",
            }}
          />
        }
        noDataMsg="No citizenship detail found"
        isDisableEdit={(c) => {
          const added = some(
            Data,
            ({ individualId }) =>
              String(individualId) === String(c.dummyIndividualId)
          );
          return !added;
        }}
        onEdit={toggleEditModal}
        loadingContent={isFetchingData}
        getId={(params) => params.dummyIndividualId}
        getName={({ firstName, lastName }) => {
          return `${firstName} ${lastName}`;
        }}
        renderMenuButton={(params, options) => {
          return <MemberMenuButton member={params} options={options} />;
        }}
      >
        {(selected) => {
          const age = getAge(selected.dateOfBirth);
          return (
            <>
              <CitizenshipContent
                selected={selected}
                addCitizenship={toggleAddModal}
                age={age}
                getLkpMatch={getLkpMatch}
                pageData={Data}
                setSelectedData={setSelectedData}
              />
              {openAddModal && (
                <CitizenshipForm
                  open={openAddModal}
                  onClose={toggleAddModal}
                  getOptions={getOptions}
                  individualId={selected.dummyIndividualId}
                  age={age}
                  ScreenProvider={ScreenProvider}
                  labels={labels}
                  selected={selected}
                  saveJson={saveJson}
                  retrieveJson={retrieveJson}
                  pageData={Data}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                />
              )}
              {openEditModal && (
                <CitizenshipForm
                  editMode
                  open={openEditModal}
                  onClose={toggleEditModal}
                  getOptions={getOptions}
                  ScreenProvider={ScreenProvider}
                  labels={labels}
                  individualId={selected.dummyIndividualId}
                  selected={selected}
                  age={age}
                  saveJson={saveJson}
                  pageData={Data}
                  retrieveJson={retrieveJson}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                />
              )}
            </>
          );
        }}
      </SelectableView>
    </PageContainer>
  );
};

export default Citizenship;

import { createContext, useMemo, useState } from "react";
import useMount from "./useMount";
import HttpClient from "../utils/HttpClient";
import { useSelector } from "react-redux";
import { selecttActiveContId } from "../store/selectors/appSelector";
import { STATUS_RETRIEVE } from "../store/types/appTypes";
import { selectNavLinks } from "../store/selectors/navigationSelector";
import { find, findIndex, flatten, map, noop, some } from "lodash";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "../store/selectors/authSelector";
import { eventEmitter } from "../App";
import useLoader from "./useLoader";
export const ScreenContext = createContext();

const usePageApi = (
  { screenId, screenDefn, callRetriveJson = true, callRetrieveStatus = false },
  options = {}
) => {
  const { getLabels = true, gatePageData = true } = options;
  const controlId = useSelector(selecttActiveContId);
  const navLinks = useSelector(selectNavLinks);
  const [labels, setLabels] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [isFetchingLabels, setFetchingLabels] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isFetchingData, setFetchingData] = useState(false);
  const navigate = useNavigate();
  const token = useSelector(getAuthToken);
  const [showLoader, hideLoader] = useLoader();

  const { prevPage, nextPage, moduleId } = useMemo(() => {
    const currentModule = find(navLinks, ({ subNavs }) =>
      some(subNavs, ({ screen_id }) => {
        return screenId === screen_id;
      })
    );

    const subNavs = map(navLinks, ({ subNavs }) => subNavs);
    const flattenNavs = flatten(map(subNavs));
    const currentScreenIndex = findIndex(flattenNavs, { screen_id: screenId });
    const prevPage = flattenNavs?.[currentScreenIndex - 1]?.screen_defn;
    const nextPage = flattenNavs?.[currentScreenIndex + 1]?.screen_defn;

    return {
      prevPage,
      nextPage,
      moduleId: currentModule?.module_id,
    };
  }, [navLinks, screenId]);

  const saveScreenStatus = async (cb = noop) => {
    try {
      showLoader();
      const res = await HttpClient.post(
        "/eeapi/uiLable/pageModuleStatus/create",
        {
          controlId,
          moduleId,
          screenId,
          screenCompletedCount: "1",
        }
      );
      if (res.status === 200) {
        cb();
      }
      return res;
    } catch (error) {
      //
    } finally {
      hideLoader();
    }
  };

  const handleNext = () => {
    saveScreenStatus(() => navigate(nextPage));
  };

  const saveJson = async (payload, cId) => {
    const saveControl = cId?.controlId ?? controlId;
    try {
      showLoader();
      setSaving(true);
      const res = await HttpClient({
        url: `/eeapi/public/consumer/jsonSave/${saveControl}/${screenId}`,
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        data: payload,
      });
      return res;
    } catch (error) {
      //
    } finally {
      setSaving(false);
      hideLoader();
    }
  };

  const retrieveJson = async () => {
    if (token) {
      try {
        showLoader();
        setFetchingData(true);
        const res = await HttpClient.post(
          "/eeapi/public/consumer/jsonRetrieve",
          {
            screenDefn: screenDefn,
            controlId: controlId,
          }
        );
        if (res.data) {
          if (res.data === "No Json Doc found") {
            setPageData([]);
          } else {
            setPageData(JSON.parse(res.data));
          }
        }
      } catch (error) {
        //
      } finally {
        setFetchingData(false);
        hideLoader();
      }
    }
  };

  const fetchLabels = async (ln) => {
    if (token) {
      const choosenLn = localStorage.getItem("lang");
      const lang = ln ?? choosenLn ?? "en_US";
      if (lang) {
        localStorage.setItem("lang", lang);
      }
      try {
        showLoader();
        setFetchingLabels(true);
        const res = await HttpClient.get(
          `/eeapi/uiLable/screenLables/${screenId}/${lang}/CP`
        );
        if (res.status === 200) {
          const { screenDetail = "" } = res.data ?? {};
          setLabels(JSON.parse(screenDetail));
        }
      } catch (error) {
        //
      } finally {
        hideLoader();
        setFetchingLabels(false);
      }
    }
  };

  useMount((dispatch) => {
    if (getLabels) {
      fetchLabels();
    }

    if (callRetrieveStatus) {
      dispatch({ type: STATUS_RETRIEVE });
    }

    if (callRetriveJson && gatePageData) {
      retrieveJson(controlId, screenId, screenDefn);
    }

    eventEmitter.on("ln-change", fetchLabels);
  });

  const ScreenProvider = ({ children }) => {
    return (
      <ScreenContext.Provider value={{ screenId, labels }}>
        {children}
      </ScreenContext.Provider>
    );
  };
  return {
    fetchLabels,
    isFetchingLabels,
    saveJson,
    pageData,
    retrieveJson,
    labels,
    saving,
    isFetchingData,
    controlId,
    ScreenProvider,
    handleNext,
  };
};

export default usePageApi;

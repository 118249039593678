import React, { useEffect, useState, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selecttActiveContId } from "../../../store/selectors/appSelector";
import PageContainer from "../../../components/PageContainer";
import SelectableView from "../../../components/SelectableView";
import MemberMenuButton from "../../../components/SelectableView/MemberMenuButton";
import usePageApi from "../../../hooks/usePageApi";
import MemberDatas from "./MemberSideInfo";
import ScreenProvider from "../../../containers/ScreenProvider";
import MemberForm from "./MemberForm";
import usePageNav from "../../../hooks/usePageNav";
import { filter, isEmpty, map } from "lodash";
import useDynamicNav from "../../../hooks/useDynamicNav";
import {
  deleteMember,
  getHohDetails,
} from "../../../services/MemberDatas/apiRequest";
import MemberDeleteModal from "./MemberSideInfo/MemberDeleteModal";
import { FETCH_MEMBERS } from "../../../store/types/membersListType";

export default function MemberSummary({ screenId }) {
  const selectableViewRef = createRef();
  const { navigations, caseId } = usePageNav();
  const { handleNext } = useDynamicNav({ screenId });
  const controlId = useSelector(selecttActiveContId);
  const [data, setData] = useState([]);
  const [showAddMember, setAddMember] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState({});
  const [isHOH, setIsHoh] = useState(false);
  const [showEditMember, setEditMember] = useState(false);
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { pageData, labels, retrieveJson } = usePageApi({
    screenDefn: "/dashboard/consumer-app/intake-app/members/memberInfo",
    screenId,
    callRetrieveStatus: true,
  });

  useEffect(() => {
    retrieveJson();
    dispatch({ type: FETCH_MEMBERS });
  }, [openDeleteModal]);

  const { pageData: selectedPrograms } = usePageApi(
    {
      screenDefn: "/dashboard/consumer-app/intake-app/assistance/progSelect",
    },
    {
      getLabels: false,
    }
  );

  const choosenPrograms = map(selectedPrograms, ({ programSelected = {} }) => {
    return programSelected?.programName;
  });

  const getHohData = async () => {
    const hohData = await getHohDetails(controlId);
    const parsedData = JSON.parse(hohData.data);
    const groupedObj = {
      ...parsedData?.[0]?.[0],
      ...parsedData?.[1]?.[0],
      ...parsedData?.[2],
    };
    let hohDatas = {};
    let addHoh = [];

    if (JSON.parse(hohData.status) === 200 && parsedData?.[0]) {
      hohDatas = {
        ...groupedObj,
        dummyIndividualId: parsedData?.[0]?.[0]?.individualId,
        individualId: parsedData?.[0]?.[0]?.individualId,
        isHoh: groupedObj.clientContainer?.client?.headOfHouseholdYN,
        clientContainer: {
          client: {
            ...groupedObj?.clientContainer?.client,
            address: {
              ...groupedObj?.contactInformationDetails?.contactInformation
                .homeAddress,
            },
            race: {
              primaryRace: groupedObj?.race ?? "",
            },
            blindYN: groupedObj?.visuallyImpairedYN,
            deafYN: groupedObj?.hearingImpairedYN,
            disabledYN: groupedObj?.physicallyImpairedYN,
            ethnicity: groupedObj?.ethnicity,
          },
        },
      };
    }

    if (!isEmpty(pageData)) {
      addHoh = pageData?.map((obj) => {
        return {
          ...obj,
          isHoh: obj.clientContainer.client.headOfHouseholdYN,
          dummyIndividualId: obj.individualId,
        };
      });
    }
    if (!isEmpty(hohDatas)) setData([hohDatas, ...addHoh]);
  };

  useEffect(() => {
    getHohData();
  }, [pageData, openDeleteModal]);

  const toggleAddMember = () => {
    setAddMember(!showAddMember);
  };

  const toggleAddHOH = () => {
    navigations.toHoh();
  };

  const toggleEditMember = () => {
    if (isHOH) {
      navigations.toHoh();
    } else {
      setEditMember(!showEditMember);
    }
  };

  const onBack = () => {
    navigations.toContactInfo();
  };

  const toggleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const onDeleteMember = (member = {}) => {
    setOpenDeleteModal(true);
    setMemberToDelete(member);
  };

  const fetchHouseMembers = async () => {
    const activeMembers = filter(
      pageData,
      (obj) => obj.individualId !== memberToDelete.individualId
    );

    try {
      const res = await deleteMember(
        memberToDelete.controlId,
        JSON.stringify([
          { ...memberToDelete, dirtyStatus: "DELETE" },
          ...activeMembers,
        ])
      );
    } catch (e) {
      console.log(e);
    } finally {
      setOpenDeleteModal(false);
    }
  };
  const onDeleteMemberScs = () => {
    fetchHouseMembers();
    selectableViewRef.current?.setSelectedIdx(0);
  };

  const onNext = () => {
    handleNext();
    // navigations.toRelationShip();
  };

  const onAddMember = () => {
    retrieveJson();
  };

  return (
    <ScreenProvider labels={labels}>
      {showAddMember && (
        <MemberForm
          onAddMember={onAddMember}
          choosenPrograms={choosenPrograms}
          open={showAddMember}
          toggle={toggleAddMember}
        />
      )}
      <PageContainer
        title="Household Members Information"
        onBack={onBack}
        onNext={onNext}
      >
        <SelectableView
          data={data}
          title="Members"
          showAdd
          showEdit
          showDelete
          onDelete={onDeleteMember}
          addDataMsg="Add Head of household"
          onAddHoh={toggleAddHOH}
          noDataMsg="No household member found!"
          onAdd={() => toggleAddMember()}
          isDisableDelete={({ isHoh }) => {
            return isHoh === "Y";
          }}
          onEdit={() => toggleEditMember()}
          getId={(params) => {
            return params?.individualId;
          }}
          getAge={(params) => {
            return params?.clientContainer?.client?.dob;
          }}
          getName={(params) => {
            const { firstName, lastName } =
              params?.clientContainer?.client?.personName ?? {};
            return `${firstName} ${lastName}`;
          }}
          renderMenuButton={(params, options) => {
            return (
              <MemberMenuButton
                member={params}
                options={options}
                // genderLkp={lkpFlatten?.Gender}
              />
            );
          }}
        >
          {(selected) => {
            setIsHoh(selected.isHoh === "Y");
            return (
              <>
                <MemberDatas
                  selected={selected}
                  pageData={pageData}
                  labels={labels}
                />
                <MemberDeleteModal
                  open={openDeleteModal}
                  close={toggleDeleteModal}
                  onDelete={onDeleteMemberScs}
                  // reasons={getOptions("DeleteMemberReasonCode")}
                  memberToDelete={memberToDelete}
                  caseId={caseId}
                />
                {showEditMember && (
                  <MemberForm
                    pageData={pageData}
                    isEdit
                    selected={selected}
                    open={showEditMember}
                    toggle={toggleEditMember}
                    onAddMember={onAddMember}
                    choosenPrograms={choosenPrograms}
                  />
                )}
              </>
            );
          }}
        </SelectableView>
      </PageContainer>
    </ScreenProvider>
  );
}

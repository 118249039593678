import React, { useEffect, useMemo, useState } from "react";
import usePageApi from "../../hooks/usePageApi";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import Field from "../../components/Field";
import { useForm } from "react-hook-form";
import LoginImage from "../../assets/images/login-image.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import usePageNav from "../../hooks/usePageNav";
import ScreenProvider from "../../containers/ScreenProvider";
import CoTextField from "../../controllers/CoTextField";
import { useDispatch } from "react-redux";
import Dropdown from "../../components/Dropdown";
import useLookup from "../../hooks/useLookup";
import { authApi, authApiCreate } from "../../utils/HttpClient";
import useLoader from "../../hooks/useLoader";
import { showErrorToast, showSuccessToast } from "../../utils/services/toast";

const Registration = () => {
  const { labels } = usePageApi({
    screenId: 427,
  });
  const { getOptions, getLkpMatch } = useLookup("Roles");
  const dispatch = useDispatch();
  const [showLoader, hideLoader] = useLoader();
  const { navigations } = usePageNav();
  const [accessToken, setaccessToken] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { watch, control, getValues } = useForm();

  useEffect(() => {
    adminLogin();
  }, []);

  const adminLogin = async () => {
    try {
      showLoader();
      var bodyFormData = new URLSearchParams();
      bodyFormData.append("grant_type", "password");
      bodyFormData.append("client_id", "security-admin-console");
      bodyFormData.append("username", "test@kyyba.com");
      bodyFormData.append("password", "test123");
      bodyFormData.append("scope", "openid");
      const res = await authApi.post(
        `${process.env.REACT_APP_REALMS}/protocol/openid-connect/token`,
        bodyFormData
      );
      setaccessToken(res?.data?.access_token);
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const register = async () => {
    const payload = {
      firstName: getValues("fname"),
      lastName: getValues("lname"),
      email: getValues("email"),
      userName: "",
      password: getValues("pass"),
      conformPass: getValues("conformPass"),
      roles: [getValues("role")],
      superviosrId: 80,
      superviosrUuid: "0fcfd081-c86e-4940-a5a7-41b546d20d10",
      country: "USA",
    };
    try {
      showLoader();
      if (payload.password === payload.conformPass) {
        payload.userName = payload.firstName + " " + payload.lastName;
        const responseToken = await authApiCreate.post(
          `/keycloak/${process.env.REACT_APP_REALMS}/createUser`,
          payload,
          {
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (responseToken.status === 200) {
          forgotPassowrd(responseToken.data);
          showSuccessToast("User Created Successfully. ");
          navigations.toLogin();
        }
      }
    } catch (error) {
      showErrorToast("Server Error");
    } finally {
      hideLoader();
    }
  };

  const forgotPassowrd = async (payload) => {
    if (payload.username) {
      try {
        showLoader();
        const responseToken = await authApiCreate.post(
          `/keycloak/${process.env.REACT_APP_REALMS}/updatePasswordRequiredAction`,
          payload
        );
        if (responseToken.status === 200) {
          showSuccessToast("Please check your mail for update password");
        }
      } catch (error) {
        showErrorToast("Server Error");
      } finally {
        hideLoader();
      }
    } else {
      showErrorToast("Enter Mail ID");
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // useMemo(() => {
  //   authApiCreate.interceptors.request.handlers = [];
  //   authApiCreate.interceptors.request.use(
  //     (config) => {
  //       config.headers = {
  //         ...config.headers,
  //         authorization: `Bearer ${accessToken}`,
  //         Origin: "http://localhost:3000",
  //       };
  //       return config;
  //     },
  //     (error) => {
  //       return Promise.reject(error);
  //     }
  //   );
  // }, [accessToken]);

  const { role } = useMemo(() => {
    return {
      role: getOptions("Roles_Cp"),
    };
  }, [getOptions, accessToken]);

  return (
    <>
      <ScreenProvider labels={labels}>
        <Grid
          container
          sx={{
            width: "60%",
            margin: "auto",
          }}
        >
          <Grid
            item
            xs={6}
            sx={{
              backgroundColor: "#44A0E3",
              borderRadius: "20px 0px 0px 20px",
              backgroundImage: `url(${LoginImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "right",
              padding: "1rem",
            }}
          >
            <Typography variant="h2" color="#fff">
              Let us help you applying your scheme
            </Typography>
            <Typography variant="h5" color="#fff">
              Our process is quick and easy, to get a scheme from Government to
              exact user
            </Typography>
          </Grid>
          <Grid
            container
            sx={{
              padding: "2.5rem",
              width: 500,
              boxShadow: 3,
              borderRadius: "0px 20px 20px 0px",
              gap: "20px",
            }}
            xs={6}
          >
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center", gap: "20px" }}
            >
              <Typography variant="h4grey">Account Registration</Typography>
            </Grid>
            <Grid item xs={12}>
              <Field name="fname" label="First Name" required>
                <CoTextField
                  name="fname"
                  control={control}
                  placeholder={labels?.emailOrPhone ?? "Enter First Name"}
                />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="lname" label="Last Name" required>
                <CoTextField
                  name="lname"
                  control={control}
                  placeholder={labels?.emailOrPhone ?? "Enter Lase Name"}
                />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="email" label="Email" required>
                <CoTextField
                  name="email"
                  control={control}
                  placeholder={labels?.emailOrPhone ?? "Email or userName"}
                />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="role" label="Role">
                <Dropdown name="role" control={control} options={role} />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="pass" label="Password" required>
                <CoTextField
                  name="pass"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  // value={password}
                  // onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  control={control}
                />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="conformPass" label="Conform Password" required>
                <CoTextField
                  name="conformPass"
                  placeholder="Conform Password"
                  control={control}
                />
              </Field>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={() => register()}
                sx={{
                  width: "100%",
                  padding: "15px",
                }}
              >
                {labels?.register ?? "Register"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </ScreenProvider>
    </>
  );
};

export default Registration;
